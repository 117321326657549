'use client';

import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import Box from '@mui/material/Box';
import { ConditionalWrapper } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { useSession } from 'next-auth/react';
import { useEnvContext } from 'next-runtime-env';

import { Action, Subject } from '../../modules/authorization/authorization';
import useAuthorization from '../../modules/authorization/hooks/use-auth';
import copConfig from '../../modules/content-platform/config/cop-config';
import ContentPlatformProvider from '../../modules/content-platform/context/content-platform-provider';
import CustomerProvider from '../../modules/customers/context/customer-provider';
import { CustomerSearchState } from '../../modules/customers/customer';
import uacConfig from '../../modules/users/automated-user-creation/config/uac-config';
import AutomatedUserCreationProvider from '../../modules/users/automated-user-creation/context/automated-user-creation-provider';
import Forbidden from './forbidden';
import ViewHeader from './view-header';
import ProcessEnv = NodeJS.ProcessEnv;
import { DeviceServiceProvider } from '@zetadisplay/engage-components/modules/devices/context';

import EngageDependencyInjection from '../../modules/dependency-injection/components/engage-dependency-injection';

const useStyles = makeStyles()((theme) => ({
    root: {
        backgroundColor: (theme.palette.dark && theme.palette.background.content) || theme.palette.background.main,
        padding: 18,
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        marginBottom: '12px',
        zIndex: 1,
    },
}));

type AuthCondition = { action: Action; subject: Subject; id?: string };

export type ContentWrapperProps = React.PropsWithChildren<{
    authCondition?: AuthCondition | AuthCondition[];
    customerSearchState?: CustomerSearchState;
    title: string;
    useAutomatedUserCreationContext?: boolean;
    useContentPlatformContext?: boolean;
    useCustomerContext?: boolean;
    useCustomerContextChanger?: boolean;
    useDeviceManagementContext?: boolean;
    useEngageDependencyInjection?: boolean;
}>;

const ContentWrapper = ({
    authCondition,
    children,
    customerSearchState,
    title,
    useAutomatedUserCreationContext = false,
    useContentPlatformContext = false,
    useCustomerContext = false,
    useCustomerContextChanger = false,
    useDeviceManagementContext = false,
    useEngageDependencyInjection = false,
}: ContentWrapperProps) => {
    const { classes } = useStyles();
    const context = useAuthorization();

    const { data } = useSession();
    const { NEXT_PUBLIC_DEVICE_SERVICE_API_BASE } = useEnvContext() as ProcessEnv;

    const isDenied =
        authCondition &&
        (Array.isArray(authCondition)
            ? authCondition.some((condition) => !context.can(condition.action, condition.subject, condition.id))
            : !context.can(authCondition.action, authCondition.subject, authCondition.id));

    return (
        <Box component="article" className={classes.root} data-testid="content-wrapper">
            <ViewHeader customerContextChanger={useCustomerContextChanger} title={title} />

            <ConditionalWrapper
                condition={useCustomerContext}
                render={(element) => (
                    <CustomerProvider customerSearchState={customerSearchState}>{element}</CustomerProvider>
                )}
            >
                <ConditionalWrapper
                    condition={useEngageDependencyInjection}
                    render={(element) => <EngageDependencyInjection>{element}</EngageDependencyInjection>}
                >
                    <ConditionalWrapper
                        condition={useDeviceManagementContext}
                        render={(element) => (
                            <DeviceServiceProvider
                                accessToken={data?.accessToken || ''}
                                basePath={NEXT_PUBLIC_DEVICE_SERVICE_API_BASE}
                            >
                                {element}
                            </DeviceServiceProvider>
                        )}
                    >
                        <ConditionalWrapper
                            condition={useContentPlatformContext}
                            render={(element) => (
                                <ContentPlatformProvider apiConfig={copConfig}>{element}</ContentPlatformProvider>
                            )}
                        >
                            <ConditionalWrapper
                                condition={useAutomatedUserCreationContext}
                                render={(element) => (
                                    <AutomatedUserCreationProvider apiConfig={uacConfig}>
                                        {element}
                                    </AutomatedUserCreationProvider>
                                )}
                            >
                                {(isDenied && <Forbidden />) || <NiceModal.Provider>{children}</NiceModal.Provider>}
                            </ConditionalWrapper>
                        </ConditionalWrapper>
                    </ConditionalWrapper>
                </ConditionalWrapper>
            </ConditionalWrapper>
        </Box>
    );
};

export default ContentWrapper;
