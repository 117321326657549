import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import { BgColorExplosionProps } from '@zetadisplay/engage-components';
import { UserInformationProvider } from '@zetadisplay/engage-components/modules/auth';
import { UserInformationProviderProps } from '@zetadisplay/engage-components/modules/auth/components/user-information-provider';
import { DependencyInjectionProvider as EngageDependencyInjectionProvider } from '@zetadisplay/engage-components/modules/dependency-injection';
import { useSession } from 'next-auth/react';
import { useEnvContext } from 'next-runtime-env';

import EngageLoader from '../../engage/components/engage-loader/engage-loader';
import ProcessEnv = NodeJS.ProcessEnv;

export type EngageDependencyInjectionProps = UserInformationProviderProps;

export const defaultBgColorExplosionProps: BgColorExplosionProps = {
    sx: { minHeight: '200px', height: 'auto', width: '100%' },
};

const EngageDependencyInjection = ({
    bgColorExplosionProps,
    children,
    defaultUser,
    defaultUserLanguages,
    fetchUserAsynchronously,
}: EngageDependencyInjectionProps) => {
    const { data } = useSession();
    const { NEXT_PUBLIC_APOLLO_URI, NEXT_PUBLIC_ENGAGE_API_URL } = useEnvContext() as ProcessEnv;

    const renderLoader = useCallback(() => <EngageLoader testId="engage-loader-user-information-provider" />, []);

    return (
        <EngageDependencyInjectionProvider
            accessToken={data?.accessToken}
            apiBasePath={NEXT_PUBLIC_ENGAGE_API_URL}
            apolloUri={NEXT_PUBLIC_APOLLO_URI}
        >
            <Box sx={{ height: '100%' }}>
                <UserInformationProvider
                    bgColorExplosionProps={bgColorExplosionProps || defaultBgColorExplosionProps}
                    defaultUser={defaultUser}
                    defaultUserLanguages={defaultUserLanguages}
                    fetchUserAsynchronously={fetchUserAsynchronously}
                    renderLoader={renderLoader}
                >
                    {children}
                </UserInformationProvider>
            </Box>
        </EngageDependencyInjectionProvider>
    );
};

export default EngageDependencyInjection;
