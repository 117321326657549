import { useAsyncAbortable } from 'react-async-hook';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useNotify } from '@zetadisplay/zeta-ui-components/hooks';

import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import onErrorHandler from '../../dependency-injection/utils/on-error-handler';
import getCustomer from '../api/get-customer';

const useCustomer = (customerId?: string) => {
    const api = useAdminPanelApi();

    const notify = useNotify();

    return useAsyncAbortable<CustomerDto | undefined>(
        async () => {
            if (!customerId) {
                return undefined;
            }

            return getCustomer(customerId, api);
        },
        [api, customerId],
        {
            onError: (error) => onErrorHandler(error, notify),
        }
    );
};

export default useCustomer;
