import Box from '@mui/material/Box';

import SectionTitle from '../../../components/section-title/section-title';
import UserApplicationStatistics from './user-application-statistics';
import UserStatusStatistics from './user-status-statistics';

const UserStatistics = () => {
    return (
        <Box component="section" data-testid="user-statistics">
            <SectionTitle title="User statistics" />

            <Box sx={{ display: { xs: 'block', md: 'flex' }, padding: '12px 0' }}>
                <UserStatusStatistics />

                <UserApplicationStatistics />
            </Box>
        </Box>
    );
};

export default UserStatistics;
