import { useAsyncAbortable } from 'react-async-hook';
import { useEnvContext } from 'next-runtime-env';
import ProcessEnv = NodeJS.ProcessEnv;

const useIsEngageUp = () => {
    const { NEXT_PUBLIC_ENGAGE_API_URL, NEXT_PUBLIC_STAGE = 'development' } = useEnvContext() as ProcessEnv;

    return useAsyncAbortable<unknown>(async () => {
        // HOTFIX as apparently healthcheck endpoint is not available on production...
        // TODO AND on tests something is flaky atm
        if (NEXT_PUBLIC_STAGE === 'production' || process.env.NODE_ENV === 'test') {
            return Promise.resolve(true);
        }

        const response = await fetch(`${NEXT_PUBLIC_ENGAGE_API_URL}/hc`).then((res) => res);

        if (!response.ok) {
            return Promise.resolve(false);
        }

        return Promise.resolve(true);
    }, [NEXT_PUBLIC_ENGAGE_API_URL, NEXT_PUBLIC_STAGE]);
};

export default useIsEngageUp;
