import(/* webpackMode: "eager" */ "/app/app/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Sarabun\",\"arguments\":[{\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin-ext\"],\"variable\":\"--font-sarabun\",\"weight\":[\"300\",\"400\",\"600\",\"700\",\"800\"]}],\"variableName\":\"sarabun\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"style\":[\"normal\"],\"subsets\":[\"latin-ext\"],\"variable\":\"--font-inter\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter_Tight\",\"arguments\":[{\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin-ext\"],\"variable\":\"--font-inter-tight\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"interTight\"}");
