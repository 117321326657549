import { ApplicationDto, CustomerDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

// Interface for configurable Applications on UI
export interface Application {
    id: ApplicationDto['externalSystem'];
    configurable: boolean | ((customer: CustomerDto | undefined) => boolean);
    enabled: boolean | ((user: UserDto) => boolean);
    name: string;
    nameAlt?: string;
    externalLinkType?: string;
}

export const APPLICATIONS: Application[] = [
    {
        id: ApplicationDto.externalSystem.ADMIN_PANEL,
        configurable: (customer) => (customer?.id && true) || false,
        enabled: true,
        name: 'Admin Panel',
    },
    { id: ApplicationDto.externalSystem.CONTENT_EDITOR, configurable: false, enabled: true, name: 'Content Creator' },
    { id: ApplicationDto.externalSystem.CONTROL, configurable: false, enabled: false, name: 'Control' },
    {
        id: ApplicationDto.externalSystem.ENGAGE,
        configurable: true,
        enabled: true,
        name: 'Engage',
        externalLinkType: 'workspace',
    },
    {
        id: ApplicationDto.externalSystem.ENGAGE_PLUS,
        configurable: false,
        enabled: false,
        name: 'Engage+',
        nameAlt: 'Engage Plus',
    },
    {
        id: ApplicationDto.externalSystem.UAC,
        configurable: false,
        enabled: (user) => user.type === UserDto.type.ADMINISTRATOR,
        name: 'UAC',
    },
];
