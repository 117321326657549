import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    root: {
        borderBottom: (theme.palette.dark && `1px solid rgba(255, 255, 255, 0.15)`) || `1px solid rgba(0, 0, 0, 0.15)`,
        display: 'block',
        marginBottom: 10,
        paddingBottom: 8,
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    title: {
        fontSize: 26,
        marginRight: 24,
    },
}));

export type ViewTitleProps = {
    customerContextChanger: boolean;
    title: string;
};

const ViewHeader = ({ customerContextChanger, title }: ViewTitleProps) => {
    const { classes } = useStyles();

    return (
        <header className={classes.root} data-testid="view-header">
            <Typography className={classes.title} variant="h1" sx={{ display: { xs: 'block', md: 'flex' } }}>
                {title}
            </Typography>

            <Box
                component="div"
                id="customerContextChangerPortal"
                sx={{ display: (!customerContextChanger && 'none') || undefined }}
            />
        </header>
    );
};

export default ViewHeader;
