import React, { useMemo } from 'react';
import { OpenAPIConfig } from '@zetadisplay/connect-contentplatform-api-client';
import { useSession } from 'next-auth/react';
import { useEnvContext } from 'next-runtime-env';

import createCopApi from '../utils/create-cop-api';
import ContentPlatformContext from './content-platform-context';
import ProcessEnv = NodeJS.ProcessEnv;

type Props = React.PropsWithChildren<{
    accessToken?: string;
    apiConfig: OpenAPIConfig;
}>;

const ContentPlatformProvider = ({ accessToken, apiConfig, children }: Props) => {
    const { NEXT_PUBLIC_COP_API_BASE } = useEnvContext() as ProcessEnv;

    const { data } = useSession();

    const values = useMemo(
        () => ({
            api: createCopApi({
                ...apiConfig,
                BASE: NEXT_PUBLIC_COP_API_BASE,
                TOKEN: accessToken || data?.accessToken,
            }),
        }),

        [NEXT_PUBLIC_COP_API_BASE, accessToken, apiConfig, data?.accessToken]
    );

    return <ContentPlatformContext.Provider value={values}>{children}</ContentPlatformContext.Provider>;
};

export default ContentPlatformProvider;
