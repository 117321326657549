import React from 'react';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import LoaderWithBackground from '../../../components/loader-with-background/loader-with-background';
import useCurrentUser from '../../users/hooks/use-current-user';
import { AbilityProvider } from './ability-provider';
import AuthorizationContext from './authorization-context';

export type AuthorizationProviderProps = React.PropsWithChildren<{
    defaultUser?: UserDto;
}>;

const AuthorizationProvider = ({ children, defaultUser }: AuthorizationProviderProps) => {
    const { result: currentUser } = useCurrentUser(defaultUser);

    if (!currentUser) {
        return <LoaderWithBackground />;
    }

    return (
        <AuthorizationContext.Provider value={currentUser}>
            <AbilityProvider currentUser={currentUser}>{children}</AbilityProvider>
        </AuthorizationContext.Provider>
    );
};

export default AuthorizationProvider;
