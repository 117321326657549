import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import usePortal from 'react-useportal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';
import { useSessionStorage } from '@zetadisplay/zeta-ui-components/hooks';

import CustomerContextChanger from '../components/customer-context-changer/customer-context-changer';
import CustomerInput from '../components/customer-input/customer-input';
import { CustomerSearchState } from '../customer';
import { emitCustomerContextChangedEvent } from '../events/customer-context-changed-event';
import useCustomer from '../hooks/use-customer';
import useCustomers from '../hooks/use-customers';
import CustomerContext from './customer-context';

export type CustomerProviderProps = React.PropsWithChildren<{
    customerSearchState?: CustomerSearchState;
    initialCustomerId?: string;
}>;

const CustomerProvider = ({ children, customerSearchState, initialCustomerId }: CustomerProviderProps) => {
    const [customerId, setCustomerId] = useSessionStorage<string | undefined>(
        'adminpanel.options.customer',
        initialCustomerId
    );

    const { data, isLoading } = useCustomers(customerSearchState);
    const customer = useCustomer(customerId);
    const methods = useForm();

    const { Portal } = usePortal({
        bindTo: (document && document.getElementById('customerContextChangerPortal')) || undefined,
    });

    const handleCustomerChange = useCallback(
        (arg: CustomerDto | undefined) => {
            setCustomerId(arg?.id || undefined);
            emitCustomerContextChangedEvent(arg || undefined);
        },
        [setCustomerId]
    );

    /**
     * Check if current customer is in the list of customers. When navigating to a page with a customer context
     * it is highly possible that the previous scope is not valid when search state is different, mainly because of
     * application requirements.
     */
    const isCustomerInList = useMemo(() => {
        if (!data || data?.length === 0) {
            return undefined;
        }

        return data.some((_customer) => _customer.id === customerId);
    }, [customerId, data]);

    /**
     * If customer has resolved and is not in the list of customers, reset customer id.
     */
    useEffect(() => {
        if (isCustomerInList === false && customer.result) {
            handleCustomerChange(undefined);
        }
    }, [customer.result, handleCustomerChange, isCustomerInList]);

    const values = useMemo(
        () => ({
            changeCustomer: handleCustomerChange,
            customer: customer.result,
            customers: data,
        }),
        [customer.result, data, handleCustomerChange]
    );

    /**
     * When loading data or not yet resolved if customer is in the list, show loader.
     */
    if (isLoading || customer.loading || isCustomerInList === undefined) {
        return (
            <Box data-testid="customer-provider-loader">
                <ComponentLoader />
            </Box>
        );
    }

    /**
     * If customer is not resolved or not in the list, show customer input.
     */
    if (!customer.result || !isCustomerInList) {
        return (
            <FormProvider {...methods}>
                <Box sx={{ width: '400px' }}>
                    <Typography sx={{ marginBottom: '12px' }}>Please select customer to continue</Typography>

                    <CustomerInput
                        fullWidth
                        loading={isLoading}
                        name="select-customer-context"
                        onChangeCallback={handleCustomerChange}
                        options={data}
                    />
                </Box>
            </FormProvider>
        );
    }

    return (
        <CustomerContext.Provider value={values}>
            <Portal>
                <CustomerContextChanger />
            </Portal>

            {children}
        </CustomerContext.Provider>
    );
};

export default CustomerProvider;
