'use client';

import ContentWrapper from '../src/layout/components/content-wrapper';
import Dashboard from '../src/modules/dashboard/dashboard';

const Page = () => {
    return (
        <ContentWrapper title="Dashboard">
            <Dashboard />
        </ContentWrapper>
    );
};

export default Page;
