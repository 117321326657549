import React, { ComponentProps, useCallback, useMemo } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import DarkMode from '@mui/icons-material/DarkMode';
import LightMode from '@mui/icons-material/LightMode';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import {
    ActionGroup,
    ApplicationAccessMenu,
    ApplicationListApplicationConfigurations,
    Thumbnail,
    UserMenu,
} from '@zetadisplay/zeta-ui-components';
import { ApplicationAdminPanel } from '@zetadisplay/zeta-ui-components/assets';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import Link from 'next/link';
import { signOut, useSession } from 'next-auth/react';
import { useEnvContext } from 'next-runtime-env';

import useCurrentUser from '../../modules/authorization/hooks/use-current-user';
import useAdminPanelApi from '../../modules/dependency-injection/hooks/use-admin-panel-api';
import UserProfileModal, { UserProfileModalProps } from '../../modules/user-profile/components/user-profile-modal';
import resetPassword from '../../modules/users/api/reset-password';
import ProcessEnv = NodeJS.ProcessEnv;

import imageRenderer from '../../utils/image-renderer/image-renderer';

const useStyles = makeStyles()((theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: (theme.palette.dark && '#030303') || '#fff',
        display: 'flex',
        height: 84,
        marginBottom: 41,
        padding: '0 14px 0 20px',
    },
    logo: {
        backgroundColor: '#fff',
        borderRadius: '50%',
        padding: 8,
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

type HeaderProps = {
    darkMode: boolean;
    setDarkMode: (darkMode: boolean) => void;
};

const Header = ({ darkMode, setDarkMode }: HeaderProps) => {
    const { classes, cx } = useStyles();
    const { NEXT_PUBLIC_CONTENT_EDITOR_URL, NEXT_PUBLIC_ENGAGE_UI_URL, NEXT_PUBLIC_OKTA_URL } =
        useEnvContext() as ProcessEnv;

    const { data } = useSession();
    const currentUser = useCurrentUser();
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();

    const appConfigs: ApplicationListApplicationConfigurations = useMemo(
        () => ({
            AdminPanel: {
                href: '',
            },
            ContentEditor: {
                href: NEXT_PUBLIC_CONTENT_EDITOR_URL,
            },
            Engage: {
                href: NEXT_PUBLIC_ENGAGE_UI_URL,
            },
        }),
        [NEXT_PUBLIC_CONTENT_EDITOR_URL, NEXT_PUBLIC_ENGAGE_UI_URL]
    );

    const logout = useCallback(async () => {
        await signOut({ redirect: false }).then(() => {
            window.location.href = `${NEXT_PUBLIC_OKTA_URL}/login/signout`;
        });
    }, [NEXT_PUBLIC_OKTA_URL]);

    const actions: ActionGroup<UserDto | undefined>[] = useMemo(() => {
        return [
            {
                actions: [
                    {
                        handler: () => null,
                        isDisabled: () => true,
                        label: (currentUser && `${currentUser.firstName} ${currentUser.lastName}`) || '',
                        name: 'user-info',
                    },
                ],
                name: 'header',
            },
            {
                actions: [
                    {
                        handler: (user) =>
                            NiceModal.show<UserDto, ComponentProps<typeof UserProfileModal>, UserProfileModalProps>(
                                UserProfileModal,
                                {
                                    externalProfile: user?.externalProfile,
                                }
                            ),
                        label: 'Profile',
                        name: 'user-profile',
                    },
                    {
                        handler: async (user) => {
                            if (!user) {
                                return;
                            }

                            await resetPassword(user, api)
                                .then(() =>
                                    snackbar('Password reset request was sent successfully', undefined, 'success')
                                )
                                .catch((error) =>
                                    snackbar(`Password reset request failed: ${error.message}`, undefined, 'error')
                                );
                        },
                        isDisabled: (user) => ((!user || user.externalProfile) && true) || false,
                        label: 'Reset password',
                        name: 'reset-password',
                    },
                    { label: 'Sign out', name: 'sign-out', handler: logout },
                ],
                name: 'actions',
            },
        ];
    }, [currentUser, logout, api, snackbar]);

    const iconColor = useMemo(() => (darkMode ? '#fff' : '#000'), [darkMode]);

    return (
        <header className={cx(classes.root, 'no-printing')} data-testid="header">
            <Link href="/" data-testid="adminpanel-home" prefetch={false}>
                <div className={classes.logo}>
                    <Thumbnail alt="AdminPanel" size={40} source={ApplicationAdminPanel} />
                </div>
            </Link>

            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: 24,
                    lineHeight: '24px',
                    marginLeft: '12px',
                }}
            >
                Admin Panel
            </Typography>

            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', paddingRight: '22px' }}>
                <IconButton data-testid="toggle-theme" onClick={() => setDarkMode(!darkMode)}>
                    {(darkMode && <LightMode sx={{ height: 24, color: iconColor }} />) || (
                        <DarkMode sx={{ height: 24, color: iconColor }} />
                    )}
                </IconButton>

                <ApplicationAccessMenu
                    applicationConfigurations={appConfigs}
                    groups={data?.groups || []}
                    iconRenderer={imageRenderer}
                />

                <UserMenu<UserDto | undefined> actions={actions} user={currentUser} />
            </Box>
        </header>
    );
};

export default Header;
