import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';

type EngageLoaderProps = {
    testId?: string;
    text?: string;
};

const EngageLoader = ({
    testId = 'engage-loader',
    text = 'Please wait while we communicate with Engage..',
}: EngageLoaderProps) => {
    return (
        <Box component="div" data-testid={testId} sx={{ marginTop: '12px' }}>
            <Typography sx={{ marginBottom: 1, textAlign: 'center' }}>{text}</Typography>

            <ComponentLoader />
        </Box>
    );
};

export default EngageLoader;
