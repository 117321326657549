import { AbilityBuilder } from '@casl/ability';
import { RoleDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import { ALL_ACTION_STRINGS } from '../../authorization/action';
import { AppAbilityType } from '../../authorization/authorization';
import { CustomerTreeNode } from '../../customers/customer-configuration/customer-profile';
import {
    flattenHierarchy,
    getAdminPanelRolesFromClosestConfiguration,
} from '../../customers/customer-configuration/helper/hierarchy.util';
import getUserPermissions from '../utils/get-user-permissions';
import getUsersAbilities from './get-users-abilities';

function addAbility(
    user: UserDto,
    target: UserDto,
    customers: Map<string, CustomerTreeNode>,
    { can, cannot }: AbilityBuilder<AppAbilityType>,
    limitedFeatures: boolean
): void {
    if (user.type === UserDto.type.ADMINISTRATOR) {
        can(getUsersAbilities(user, [], limitedFeatures, target), 'User', target.id);
        return;
    }

    // Iterate over all customers the target user has access to (https://zetadisplay-rnd.atlassian.net/browse/AP-607)
    // eslint-disable-next-line no-restricted-syntax
    for (const customerUser of target.customers) {
        // const customer = customers.find((item) => item.id === customerUser.customer.id);
        const customer = customers.get(customerUser.customer.id);

        if (customer) {
            // Get roles for the logged-in user on the current customer from target user iteration on that branch
            // Essentially we need to get correct permissions for the logged-in user on different possible customer hierarchy branches
            // https://zetadisplay-rnd.atlassian.net/browse/AP-607
            const roles: RoleDto[] | undefined = getAdminPanelRolesFromClosestConfiguration(
                customer,
                [...customers.values()],
                user.customers
            );

            if (!roles) {
                cannot(ALL_ACTION_STRINGS, 'User', target.id);
                return;
            }

            const permissions = roles.flatMap((role) => role.permissions.map((permission) => permission));

            const userPermissions = getUsersAbilities(user, permissions, limitedFeatures, target);

            if (userPermissions) {
                can(userPermissions, 'User', target.id);
                return;
            }

            cannot(ALL_ACTION_STRINGS, 'User', target.id);
        } else {
            cannot(ALL_ACTION_STRINGS, 'User', target.id);
        }
    }
}

const buildUserAbilities = (
    user: UserDto,
    users: UserDto[],
    customers: CustomerTreeNode[],
    abilityBuilder: AbilityBuilder<AppAbilityType>,
    limitedFeatures: boolean
) => {
    const customerIdMap = flattenHierarchy(customers);

    // Add generic ability to users module
    const userPermissions = getUserPermissions(user);

    if (
        user.type === UserDto.type.ADMINISTRATOR ||
        (userPermissions.length > 0 && userPermissions.some((permission) => permission.includes('USERS_')))
    ) {
        abilityBuilder.can(getUsersAbilities(user, userPermissions, limitedFeatures, undefined), 'User');
    } else {
        abilityBuilder.cannot(ALL_ACTION_STRINGS, 'User');
    }

    // Add abilities per each user
    users.map((target) => addAbility(user, target, customerIdMap, abilityBuilder, limitedFeatures));
};

export default buildUserAbilities;
