export const ACCESS_CONFIGURATION_BASE_PATH = 'access-configuration';
export const AUTOMATED_USER_CREATION_PATH = 'automated-user-creation';
export const SOFTWARE_CONFIGURATION_BASE_PATH = 'software-configuration';
export const SOFTWARE_CONFIG_PLAYER_CREATION_PATH = 'player-creation-tool';
export const SOFTWARE_CONFIG_TEMPLATES_PATH = 'templates';
export const SOFTWARE_CONFIG_WORKSPACES_PATH = 'workspaces';
export const DEVICE_MANAGEMENT_BASE_PATH = 'device-management';
export const DEVICE_MANAGEMENT_DEVICES_PATH = 'devices';
export const DEVICE_MANAGEMENT_SOFTWARE_VERSIONS_PATH = 'software-versions';
export const DEVICE_MANAGEMENT_FIRMWARE_VERSIONS_PATH = 'firmware-versions';
