import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { BarChart, HighlightScope, mangoFusionPaletteDark, mangoFusionPaletteLight } from '@mui/x-charts';
import { Theme } from '@zetadisplay/zeta-ui-components';
import { ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

import { APPLICATIONS } from '../../applications/application';
import useApplicationStatistics from '../../customers/hooks/use-application-statistics';

const highlightScope: HighlightScope = {
    highlighted: 'item',
    faded: 'global',
};

const CustomerApplicationStatistics = () => {
    const theme: ZetaTheme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const { result } = useApplicationStatistics();

    const width = matches ? 450 : 350;
    const height = matches ? 300 : 250;

    return (
        <Box component="section" data-testid="customer-application-statistics" sx={{ padding: '4px 8px' }}>
            <Theme dark={theme.palette.dark} nested version={2}>
                <BarChart
                    colors={(theme.palette.dark && mangoFusionPaletteDark) || mangoFusionPaletteLight}
                    xAxis={[{ scaleType: 'band', data: [`Customers by applications`] }]}
                    series={[
                        { data: [result?.adminPanel || 0], label: APPLICATIONS[0].name, highlightScope },
                        { data: [result?.engage || 0], label: APPLICATIONS[3].name, highlightScope },
                        { data: [result?.contentCreator || 0], label: APPLICATIONS[1].name, highlightScope },
                        { data: [result?.uac || 0], label: APPLICATIONS[5].name, highlightScope },
                    ]}
                    width={width}
                    height={height}
                    slotProps={{
                        legend: {
                            labelStyle: {
                                fontSize: matches ? 14 : 12,
                            },
                        },
                    }}
                />
            </Theme>
        </Box>
    );
};

export default CustomerApplicationStatistics;
