'use client';

import React, { useEffect, useState } from 'react';
import { locales } from '@zetadisplay/engage-components/locales';
import { TranslationProvider } from '@zetadisplay/zeta-localization';
import { SessionProvider } from 'next-auth/react';

import ProtectedLayout from './protected-layout';

type ProvidersProps = React.PropsWithChildren;

const Providers = ({ children }: ProvidersProps) => {
    const [mounted, setMounted] = useState(false);

    /**
     * Use client directive is not fully working solution for localStorage etc. so this trick
     * prevents client-only providers from initializing on server-side
     */
    useEffect(() => {
        setMounted(true);
    }, []);

    if (!mounted) {
        return null;
    }

    return (
        <TranslationProvider defaultLocale="en-US" translations={locales}>
            <SessionProvider>
                <ProtectedLayout>{children}</ProtectedLayout>
            </SessionProvider>
        </TranslationProvider>
    );
};

export default Providers;
