type NamedEntity = {
    name: string;
};

const sortByName = (a: NamedEntity, b: NamedEntity) => {
    if (a.name < b.name) {
        return -1;
    }

    return a.name > b.name ? 1 : 0;
};

export default sortByName;
