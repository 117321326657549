import { RoleDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import Action from '../../authorization/action';

const TYPE_HIERARCHY = {
    [UserDto.type.ADMINISTRATOR]: 0,
    [UserDto.type.ZETA_DISPLAY]: 1,
    [UserDto.type.USER]: 2,
};

const canManage = (user: UserDto, target: UserDto): boolean => {
    if (user.type === UserDto.type.ADMINISTRATOR || user.type === UserDto.type.ZETA_DISPLAY) {
        return TYPE_HIERARCHY[target.type] >= TYPE_HIERARCHY[user.type];
    }

    return target.type === UserDto.type.USER;
};

/**
 * This function maps User's permissions to allowed actions on 'User-subject'.
 *
 * @param user
 * @param permissions
 * @param limitedFeatures In case of external technical issues, permissions can be limited to read-only
 * @param target
 * @returns
 */
const getUsersAbilities = (
    user: UserDto,
    permissions: RoleDto['permissions'],
    limitedFeatures: boolean,
    target?: UserDto
): Action | Action[] | undefined => {
    if (
        user?.type === UserDto.type.ADMINISTRATOR ||
        (permissions.includes('USERS_FULL_ACCESS') && target && canManage(user, target))
    ) {
        return (limitedFeatures && [Action.Read]) || [Action.Manage, Action.Read];
    }

    if (target && permissions.includes('USERS_FULL_ACCESS') && !canManage(user, target)) {
        return Action.Read;
    }

    // User abilities are in general per user (ACL) but in case target user is missing,
    // we are returning module-level permissions to access the views
    if (!target && permissions.includes('USERS_FULL_ACCESS')) {
        return [Action.Manage, Action.Read];
    }

    if (!target && permissions.includes('USERS_VIEW_ACCESS')) {
        return Action.Read;
    }

    // Fallback when view access exists but other conditions did not match
    if (permissions.includes('USERS_VIEW_ACCESS')) {
        return Action.Read;
    }

    return undefined;
};

export default getUsersAbilities;
