import React, { ReactNode, useMemo, useState } from 'react';
import { Menu, menuClasses, Sidebar, sidebarClasses } from 'react-pro-sidebar';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ChevronLeft } from '@styled-icons/boxicons-regular';
import { CorporateFare, PeopleOutline } from '@styled-icons/material-outlined';
import { Devices } from '@styled-icons/material-rounded';
import { ApplicationDto } from '@zetadisplay/connect-adminpanel-api-client';
import { Logo } from '@zetadisplay/zeta-ui-components';
import { makeStyles, ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';
import { CSSObject } from 'tss-react';

import { APPLICATIONS } from '../../../modules/applications/application';
import ApplicationIcon from '../../../modules/applications/application-icon/application-icon';
import Action from '../../../modules/authorization/action';
import useAuthorization from '../../../modules/authorization/hooks/use-auth';
import {
    ACCESS_CONFIGURATION_BASE_PATH,
    DEVICE_MANAGEMENT_BASE_PATH,
    DEVICE_MANAGEMENT_DEVICES_PATH,
    DEVICE_MANAGEMENT_FIRMWARE_VERSIONS_PATH,
    DEVICE_MANAGEMENT_SOFTWARE_VERSIONS_PATH,
    SOFTWARE_CONFIG_PLAYER_CREATION_PATH,
    SOFTWARE_CONFIG_TEMPLATES_PATH,
    SOFTWARE_CONFIG_WORKSPACES_PATH,
    SOFTWARE_CONFIGURATION_BASE_PATH,
} from '../../../routing/constants';
import imageRenderer from '../../../utils/image-renderer/image-renderer';
import NavigationSubsection, { NavigationSubSectionType } from './navigation-subsection';

const useStyles = makeStyles()((theme) => ({
    root: {
        marginRight: 12,
    },
    collapser: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        height: 36,
        justifyContent: 'flex-end',
        padding: '0 10px',
    },
    'collapser--collapsed': {
        padding: '0 2px',
    },
    'collapser-icon': {
        transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.short,
        }),
    },
    'collapser-icon--collapsed': { transform: 'rotate(180deg)' },
    logoContainer: {
        marginTop: '60px',
        padding: '0 28px 10px',
    },
    'logo-container--collapsed': {
        display: 'none',
        transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.short,
        }),
    },
}));

export type NavigationMainSection = {
    dataTestId: string;
    icon?: ReactNode;
    sections: NavigationSubSectionType[];
    title: string;
    visible?: boolean;
};

const accessConfiguration: NavigationSubSectionType[] = [
    {
        auth: [[Action.Read, 'User']],
        dataTestId: 'users-navigation-sub-section',
        icon: React.createElement(PeopleOutline, { size: 24 }),
        title: 'Users',
        links: [
            {
                dataTestId: 'users-link',
                href: `/${ACCESS_CONFIGURATION_BASE_PATH}/users`,
                text: 'All users',
            },
            {
                dataTestId: 'user-imports-link',
                auth: [Action.Read, 'Customer'],
                href: `/${ACCESS_CONFIGURATION_BASE_PATH}/users/imports`,
                text: 'User imports',
            },
            {
                auth: [Action.Read, 'UAC'],
                dataTestId: 'uac-link',
                href: `/${ACCESS_CONFIGURATION_BASE_PATH}/users/automated-user-creation`,
                text: 'Automated user creation',
            },
        ],
    },
    {
        auth: [[Action.Read, 'Customer']],
        dataTestId: 'customers-link',
        icon: React.createElement(CorporateFare, { size: 24 }),
        title: 'Customers',
        titleHref: `/${ACCESS_CONFIGURATION_BASE_PATH}/customers`,
        links: [],
    },
];

const softwareConfiguration: NavigationSubSectionType[] = [
    {
        auth: [
            [Action.Read, 'PlayerBatch-Engage'],
            [Action.Read, 'Templates-Engage'],
        ],
        dataTestId: 'engage-navigation-sub-section',
        icon: (
            <ApplicationIcon
                application={APPLICATIONS.filter((app) => app.id === ApplicationDto.externalSystem.ENGAGE)[0]}
                size={24}
            />
        ),
        title: 'Engage',
        links: [
            {
                auth: [Action.Read, 'PlayerBatch-Engage'],
                dataTestId: 'engage-player-batches-link',
                href: `/${SOFTWARE_CONFIGURATION_BASE_PATH}/engage/${SOFTWARE_CONFIG_PLAYER_CREATION_PATH}`,
                text: 'Players',
            },
            {
                auth: [Action.Read, 'Templates-Engage'],
                dataTestId: 'engage-templates-link',
                href: `/${SOFTWARE_CONFIGURATION_BASE_PATH}/engage/${SOFTWARE_CONFIG_TEMPLATES_PATH}`,
                text: 'Templates',
            },
            {
                auth: [Action.Read, 'Workspaces-Engage'],
                dataTestId: 'engage-workspaces-link',
                href: `/${SOFTWARE_CONFIGURATION_BASE_PATH}/engage/${SOFTWARE_CONFIG_WORKSPACES_PATH}`,
                text: 'Workspaces',
                disabled: (stage: string) => stage === 'production',
            },
        ],
        rootStyles: {
            [`.${menuClasses.icon}`]: {
                backgroundColor: '#fff',
                borderRadius: '50%',
                height: '28px',
                width: '28px',
                marginRight: '12px',
                minWidth: '28px',
            },
            [`>.${menuClasses.button}`]: {
                paddingLeft: '24px !important',
            },
        },
    },
    {
        auth: [
            [Action.Read, 'Device-Management'],
            [Action.Read, 'Device-Management-Software-Versions'],
            [Action.Read, 'Device-Management-Firmware-Versions'],
        ],
        dataTestId: 'device-management-sub-section',
        icon: React.createElement(Devices, { size: 24 }),
        title: 'Device management',
        links: [
            {
                auth: [Action.Read, 'Device-Management'],
                dataTestId: 'devices-link',
                href: `/${SOFTWARE_CONFIGURATION_BASE_PATH}/${DEVICE_MANAGEMENT_BASE_PATH}/${DEVICE_MANAGEMENT_DEVICES_PATH}`,
                text: 'Devices',
                disabled: (stage: string) => stage === 'production',
            },
            {
                auth: [Action.Read, 'Device-Management-Software-Versions'],
                dataTestId: 'software-versions-link',
                href: `/${SOFTWARE_CONFIGURATION_BASE_PATH}/${DEVICE_MANAGEMENT_BASE_PATH}/${DEVICE_MANAGEMENT_SOFTWARE_VERSIONS_PATH}`,
                text: 'Software versions',
                disabled: (stage: string) => stage === 'production',
            },
            {
                auth: [Action.Read, 'Device-Management-Firmware-Versions'],
                dataTestId: 'firmware-versions-link',
                href: `/${SOFTWARE_CONFIGURATION_BASE_PATH}/${DEVICE_MANAGEMENT_BASE_PATH}/${DEVICE_MANAGEMENT_FIRMWARE_VERSIONS_PATH}`,
                text: 'Firmware versions',
                disabled: (stage: string) => stage === 'production',
            },
        ],
        rootStyles: {
            [`.${menuClasses.icon}`]: {
                width: '28px',
                marginRight: '12px',
                minWidth: '28px',
            },
            [`>.${menuClasses.button}`]: {
                paddingLeft: '24px !important',
            },
        },
    },
];

const elements: NavigationMainSection[] = [
    {
        dataTestId: 'access-configuration-navigation-main-section',
        title: 'General settings',
        sections: accessConfiguration,
    },
    {
        dataTestId: 'software-configuration-navigation-main-section',
        title: 'Software configuration',
        sections: softwareConfiguration,
    },
];

const Navigation = () => {
    const theme: ZetaTheme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const [collapsed, setCollapsed] = useState<boolean>(!matches);
    // Custom solution for active link state until react-pro-sidebar is updated to support react-router-v6
    const [currentHref, setCurrentHref] = useState<string>(window.location.pathname);

    const { classes, cx } = useStyles();
    const context = useAuthorization();

    const rootStyles: CSSObject = useMemo(
        () => ({
            backgroundColor: (theme.palette.dark && theme.palette.background.content) || theme.palette.background.main,
            borderRightWidth: 0,
            '&:not(.ps-collapsed) .ps-submenu-content ul li a': {
                paddingLeft: '65px',
            },
            width: '252px',
            minWidth: '252px',

            [`.${sidebarClasses.container}`]: {
                backgroundColor:
                    (theme.palette.dark && theme.palette.background.content) || theme.palette.background.main,
            },
            [`.${menuClasses.subMenuRoot}`]: {
                backgroundColor:
                    (theme.palette.dark && theme.palette.background.content) || theme.palette.background.main,
            },
            [`.${menuClasses.subMenuContent}`]: {
                backgroundColor:
                    (theme.palette.dark && theme.palette.background.content) || theme.palette.background.main,
            },
            [`.${menuClasses.button}`]: {
                '&:hover': {
                    backgroundColor: `${theme.palette.primary.main}60 !important`,
                },
            },
            [`.${menuClasses.active}`]: {
                textDecoration: 'underline',
            },
            [`.${menuClasses.SubMenuExpandIcon} `]: {
                height: '24px',
            },
        }),
        [
            theme.palette.background.content,
            theme.palette.background.main,
            theme.palette.dark,
            theme.palette.primary.main,
        ]
    );

    return (
        <Box component="nav" className={cx(classes.root, 'no-printing')} data-testid="navigation">
            <Sidebar collapsed={collapsed} rootStyles={rootStyles}>
                <Box className={cx({ [classes.collapser]: true, [classes['collapser--collapsed']]: collapsed })}>
                    <ChevronLeft
                        className={cx({
                            [classes['collapser-icon']]: true,
                            [classes['collapser-icon--collapsed']]: collapsed,
                        })}
                        data-testid={`collapser--${collapsed ? 'collapsed' : 'expanded'}`}
                        size={24}
                        onClick={() => setCollapsed(!collapsed)}
                    />
                </Box>

                {elements.map((element) => {
                    const visibleSections = element.sections.filter((section) => {
                        return section.auth.some((auth) => {
                            return context.can(...auth);
                        });
                    });

                    if (visibleSections.length === 0) {
                        return null;
                    }

                    return (
                        <Box key={element.title}>
                            <Typography
                                variant="h5"
                                sx={{
                                    display: (collapsed && 'none') || 'block',
                                    padding: '0 20px',
                                    lineHeight: '48px',
                                }}
                                noWrap
                            >
                                {element.title}
                            </Typography>

                            <Menu>
                                {visibleSections.map((section) => (
                                    <NavigationSubsection
                                        key={section.title}
                                        currentHref={currentHref}
                                        setCurrentHref={setCurrentHref}
                                        section={section}
                                    />
                                ))}
                            </Menu>
                        </Box>
                    );
                })}

                <Box
                    className={cx({ [classes.logoContainer]: true, [classes['logo-container--collapsed']]: collapsed })}
                >
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <a href="https://zetadisplay.com/">
                        <Logo height={30} imageRenderer={imageRenderer} />
                    </a>
                </Box>
            </Sidebar>
        </Box>
    );
};

export default Navigation;
