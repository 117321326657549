import React, { useMemo, useState } from 'react';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { Alert } from '@zetadisplay/zeta-ui-components';

import LoaderWithBackground from '../../../components/loader-with-background/loader-with-background';
import useCustomers from '../../customers/hooks/use-customers';
import useIsEngageUp from '../../engage/hooks/use-is-engage-up';
import { useUsersFetchedEventListener } from '../../users/events/users-fetched-event';
import { buildAbilityFor } from '../config';
import AbilityContext from './ability-context';

export type AbilityProviderProps = React.PropsWithChildren<{
    currentUser: UserDto;
}>;

export const AbilityProvider = ({ children, currentUser }: AbilityProviderProps) => {
    const engageIsUp = useIsEngageUp();

    const customers = useCustomers(undefined, false, false);
    const [users, setUsers] = useState<UserDto[]>([]);

    useUsersFetchedEventListener((event) => {
        setUsers(event);
    });

    const values = useMemo(
        () => buildAbilityFor(currentUser, customers.data, users, !engageIsUp.result),
        [currentUser, customers.data, engageIsUp.result, users]
    );

    if (engageIsUp.loading) {
        return <LoaderWithBackground />;
    }

    return (
        <AbilityContext.Provider value={values}>
            {!engageIsUp.result && (
                <Alert
                    dismissible
                    messages={[
                        'Due to technical problems, some features are temporarily limited or disabled. Please try again later.',
                    ]}
                    severity="warning"
                />
            )}

            {children}
        </AbilityContext.Provider>
    );
};
