import { OpenAPIConfig } from '@zetadisplay/connect-adminpanel-api-client';

const adminPanelApiConfig: OpenAPIConfig = {
    BASE: '',
    VERSION: 'v1',
    WITH_CREDENTIALS: false,
    CREDENTIALS: 'omit',
};

const config = {
    adminPanelApiConfig,
};

export default config;
