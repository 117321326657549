import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Forbidden = () => {
    return (
        <Box data-testid="forbidden-view">
            <Typography>Content you are trying to access is not available.</Typography>
        </Box>
    );
};

export default Forbidden;
