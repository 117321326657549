import React, { ReactNode } from 'react';
import { MenuItem, SubMenu } from 'react-pro-sidebar';
import Link from 'next/link';
import { useEnvContext } from 'next-runtime-env';
import { CSSObject } from 'tss-react';

import Action from '../../../modules/authorization/action';
import { Subject } from '../../../modules/authorization/authorization';
import useAuthorization from '../../../modules/authorization/hooks/use-auth';
import ProcessEnv = NodeJS.ProcessEnv;

export type NavigationLinkType = {
    auth?: [Action, Subject];
    dataTestId: string;
    disabled?: boolean | ((stage: string) => boolean);
    href: string;
    text: string;
};

export type NavigationSubSectionType = {
    auth: [Action, Subject][];
    dataTestId: string;
    icon?: ReactNode;
    links: NavigationLinkType[];
    title: string;
    titleHref?: string;
    rootStyles?: CSSObject;
};

type Props = {
    currentHref: string;
    section: NavigationSubSectionType;
    setCurrentHref: (href: string) => void;
};

const NavigationSubsection = ({ currentHref, section, setCurrentHref }: Props) => {
    const context = useAuthorization();
    const { NEXT_PUBLIC_STAGE } = useEnvContext() as ProcessEnv;

    if (section.titleHref) {
        return (
            <MenuItem
                active={currentHref === section.titleHref}
                component={<Link href={section.titleHref} prefetch={false} />}
                data-testid={`section-${section.title}`}
                icon={section.icon}
                onClick={() => section.titleHref && setCurrentHref(section.titleHref)}
            >
                {section.title}
            </MenuItem>
        );
    }

    return (
        <SubMenu
            data-testid={`section-${section.title}`}
            icon={section.icon}
            label={section.title}
            rootStyles={section.rootStyles}
        >
            {section.links
                .filter((link) => {
                    return !link.auth || context.can(...link.auth);
                })
                .map((link) => {
                    const disabled =
                        typeof link.disabled === 'function' ? link.disabled(NEXT_PUBLIC_STAGE) : link.disabled;

                    return (
                        <MenuItem
                            key={link.href}
                            active={currentHref === link.href}
                            disabled={disabled}
                            component={<Link href={link.href} prefetch={false} />}
                            onClick={() => setCurrentHref(link.href)}
                        >
                            {link.text}
                        </MenuItem>
                    );
                })}
        </SubMenu>
    );
};

export default NavigationSubsection;
