import React from 'react';
import Box from '@mui/material/Box';

import SectionTitle from '../../../components/section-title/section-title';
import CustomerApplicationStatistics from './customer-application-statistics';

const CustomerStatistics = () => {
    return (
        <Box component="section" data-testid="customer-statistics">
            <SectionTitle title="Customer statistics" />

            <CustomerApplicationStatistics />
        </Box>
    );
};

export default CustomerStatistics;
