import { ApplicationDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import getUserPermissions from '../../users/utils/get-user-permissions';
import userHasApplication from '../../users/utils/user-has-application';

const getDevicesSoftwareVersionsAbilities = (user: UserDto, limitedFeatures: boolean) => {
    const hasEngageApplicationAccess = userHasApplication(user, ApplicationDto.externalSystem.ENGAGE);

    const permissions = getUserPermissions(user);

    if (
        user.type === UserDto.type.ADMINISTRATOR ||
        (hasEngageApplicationAccess && permissions.includes('DEVICE_SOFTWARE_FULL_ACCESS'))
    ) {
        return (limitedFeatures && ['read']) || ['manage', 'read'];
    }

    if (hasEngageApplicationAccess && permissions.includes('DEVICE_SOFTWARE_VIEW_ACCESS')) {
        return 'read';
    }

    return undefined;
};

export default getDevicesSoftwareVersionsAbilities;
