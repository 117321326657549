import { ApiError } from '@zetadisplay/connect-adminpanel-api-client';
import { NotifyAction, NotifyActions } from '@zetadisplay/engage-components';
import { NotifyFunctionType } from '@zetadisplay/zeta-ui-components/hooks/use-notify';
import { SnackbarKey } from 'notistack';

import handleAdminPanelResponseError from './handle-admin-panel-response-error';

/**
 * @obsolete Look into using asyncAbortableOnErrorHandler from en-co instead
 * @param error
 * @param notify
 * @param message
 */
const onErrorHandler = (error: Error, notify: NotifyFunctionType, message?: string) => {
    const extraActions: NotifyAction[] = [];

    if (error && handleAdminPanelResponseError(error as ApiError) === 'Validating token failed') {
        extraActions.push({
            position: 20,
            label: 'Reload',
            onClick: () => {
                window.location.reload();
            },
            sx: {
                color: '#fff',
                marginLeft: '0 !important',
            },
        });
    }

    notify({
        message: message || handleAdminPanelResponseError(error),
        persist: true,
        preventDuplicate: true,
        variant: 'error',
        action: (key: SnackbarKey) => (
            <NotifyActions actions={extraActions} snackbarKey={key} error={error} notify={notify} />
        ),
    });
};

export default onErrorHandler;
