import Grid from '@mui/material/Grid';
import { SelectInput, TextInput } from '@zetadisplay/engage-components';

import { languageOptions } from '../../users/constants';

const gridItemProps = {
    item: true,
    xs: 12,
    md: 6,
};

type UserProfileFormFieldsProps = {
    isLoading: boolean;
};

const UserProfileFormFields = ({ isLoading }: UserProfileFormFieldsProps) => {
    return (
        <Grid container spacing={3}>
            <Grid {...gridItemProps}>
                <TextInput defaultLabel="Username" disabled loading={isLoading} name="username" />
            </Grid>

            <Grid {...gridItemProps}>
                <TextInput defaultLabel="Phone number" loading={isLoading} name="phoneNumber" />{' '}
            </Grid>

            <Grid {...gridItemProps}>
                <TextInput
                    defaultLabel="First name"
                    loading={isLoading}
                    name="firstName"
                    required
                    rules={{
                        required: 'common.validation.required',
                    }}
                />
            </Grid>

            <Grid {...gridItemProps}>
                <TextInput
                    defaultLabel="Last name"
                    loading={isLoading}
                    name="lastName"
                    required
                    rules={{
                        required: 'common.validation.required',
                    }}
                />
            </Grid>

            <Grid {...gridItemProps}>
                <SelectInput
                    fullWidth
                    label="Language"
                    LabelProps={{
                        backgroundColor: '#2c3138',
                    }}
                    loading={isLoading}
                    name="language"
                    options={languageOptions}
                />
            </Grid>
        </Grid>
    );
};

export default UserProfileFormFields;
