import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { BarChart, HighlightScope } from '@mui/x-charts';
import { Theme } from '@zetadisplay/zeta-ui-components';
import { ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

import useStatusStatistics from '../../users/hooks/use-status-statistics';

const highlightScope: HighlightScope = {
    highlighted: 'item',
    faded: 'global',
};

const lightThemeColors = {
    active: '#004080',
    deactivated: '#C68642',
};

const darkThemeColors = {
    active: '#277ec3',
    deactivated: '#d59755',
};

const UserStatusStatistics = () => {
    const theme: ZetaTheme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const size = matches ? 300 : 250;

    const { result } = useStatusStatistics();

    const active = result?.active || 0;
    const deactivated = result?.deactivated || 0;

    return (
        <Box component="section" data-testid="user-status-statistics">
            <Theme dark={theme.palette.dark} nested version={2}>
                <BarChart
                    xAxis={[{ scaleType: 'band', data: [`Users by status`] }]}
                    series={[
                        {
                            color: (theme.palette.dark && darkThemeColors.active) || lightThemeColors.active,
                            data: [active],
                            label: `Active`,
                            highlightScope,
                        },
                        {
                            color: (theme.palette.dark && darkThemeColors.deactivated) || lightThemeColors.deactivated,
                            data: [deactivated],
                            label: `Deactivated`,
                            highlightScope,
                        },
                    ]}
                    width={size}
                    height={size}
                    slotProps={{
                        legend: {
                            labelStyle: {
                                fontSize: matches ? 14 : 12,
                            },
                        },
                    }}
                />
            </Theme>
        </Box>
    );
};

export default UserStatusStatistics;
