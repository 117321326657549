import {
    ApplicationDto,
    CustomerDto,
    RoleDto,
    UserCustomerDto,
    UserDto,
} from '@zetadisplay/connect-adminpanel-api-client';

import { CustomerTreeNode } from '../customer-profile';
import getRoots from './get-roots';
import { findNode, traverseCustomersTopDown } from './hierarchy.iterators';

/**
 * Build tree based on list of root-nodes.
 *
 * @param customers
 * @param roots
 * @returns
 */
export function buildTrees(customers: CustomerDto[], roots: CustomerDto[]): CustomerTreeNode[] {
    if (!roots || roots.length === 0) {
        return [];
    }

    const groupByParent = (arr: CustomerDto[]) => {
        return arr.reduce<Array<CustomerTreeNode>>((acc, cur: CustomerDto) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore Cannot figure out solution currently
            return { ...acc, [cur.parentCustomerId]: [...(acc[cur.parentCustomerId] || []), cur] };
        }, []);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const processNode = (groupedNodes: any, parent: string): CustomerTreeNode[] => {
        const nodesForParent = groupedNodes[parent];
        return nodesForParent
            ? nodesForParent.map((customer: CustomerTreeNode) => {
                  const treeNode: CustomerTreeNode = {
                      ...customer,
                      depth: 0,
                      children: [],
                  };
                  treeNode.children = processNode(groupedNodes, customer.id);
                  return treeNode;
              })
            : [];
    };

    const groupedByParent = groupByParent(customers);
    const trees: CustomerTreeNode[] = [];

    for (let rootIndex = 0; roots && rootIndex < roots.length; rootIndex += 1) {
        const myRoot = roots[rootIndex];

        const rootNode: CustomerTreeNode = {
            ...myRoot,
            depth: 0,
            children: processNode(groupedByParent, myRoot?.id || '') || [],
        };
        trees.push(rootNode);
    }

    return trees;
}

/**
 * Look in the hierarchy to find out if User has the wanted permission.
 * Going up in the hierarchy using parents and checking what permissions apply.
 *
 * @param trees
 * @param currentUser
 * @param customerId
 * @param wantedPermission
 * @returns TRUE if User has access, else FALSE
 */
export function checkAccess(
    trees: CustomerTreeNode[],
    currentUser: UserDto | undefined,
    customerId: string,
    wantedPermission: string
): boolean {
    const MAX_DEPTH = 30;
    const allowed = false;

    if (!customerId || !currentUser) {
        return false;
    }

    if (currentUser.type === UserDto.type.ADMINISTRATOR) {
        return true;
    }

    let levels = 0;
    let currentId = customerId;
    const customerFilter = (cust: UserCustomerDto) => cust.customer.id === currentId;

    do {
        const startNode = findNode(trees, currentId);
        if (!startNode) {
            return false;
        }

        const currentUserCustomer = currentUser.customers.find(customerFilter);

        if (currentUserCustomer) {
            if (!currentUserCustomer?.roles || currentUserCustomer?.roles.length === 0) {
                // We have User added here: No roles => DENY
                return false;
            }
            const permissions = currentUserCustomer?.roles?.map((r) => {
                const perms = [...r.permissions];
                return perms as unknown as string[];
            });
            const permissionMap = permissions.reduce((acc, val) => {
                return [...acc, ...val];
            }, []);
            const foundPermission = permissionMap.find((r) => r.includes(wantedPermission));

            if (foundPermission && foundPermission.length) {
                return true;
            }
        }
        // No access config for User at this level
        // Going up...
        currentId = startNode.parentCustomerId;
        levels += 1;
    } while (levels < MAX_DEPTH);
    return allowed;
}

/**
 * Convert array to trees
 * @returns
 * @param customers
 */
export function buildNewHierarchy(customers: CustomerDto[] | undefined): CustomerTreeNode[] {
    if (!customers || customers?.length === 0) {
        return [];
    }

    const roots = getRoots(customers);

    if (roots) {
        return buildTrees(customers, roots);
    }
    return [];
}

/**
 * Flatten tree to array
 * @param roots
 * @returns
 */
export function flattenHierarchy(roots: CustomerTreeNode[]): Map<string, CustomerTreeNode> {
    const myMap = new Map<string, CustomerTreeNode>();
    const cb = (node: CustomerTreeNode) => {
        if (node && !myMap.has(node.id)) {
            myMap.set(node.id, node);
        }
    };
    roots.forEach((root) => traverseCustomersTopDown(root, cb));
    return myMap;
}

const getCustomerRelationsAdminPanelRoles = (
    node: CustomerDto | CustomerTreeNode,
    userCustomerRelations: UserCustomerDto[]
): RoleDto[] | undefined => {
    const roles = userCustomerRelations.find(
        (item) =>
            item.customer.id === node.id &&
            item.applications?.some(
                (app) =>
                    app.application.externalSystem === ApplicationDto.externalSystem.ADMIN_PANEL &&
                    app.application.enabled
            )
    )?.roles;

    return (roles && roles.length > 0 && roles) || undefined;
};

export const getAdminPanelRolesFromClosestConfiguration = (
    node: CustomerDto | CustomerTreeNode,
    nodes: CustomerDto[] | CustomerTreeNode[],
    userCustomerRelations: UserCustomerDto[]
): RoleDto[] | undefined => {
    const parent = nodes.find((n) => n.id === node.parentCustomerId);
    const ownRoles = getCustomerRelationsAdminPanelRoles(node, userCustomerRelations);

    // If node has own roles or no parent, return own roles what ever they are
    if (ownRoles || !parent || node.parentCustomerId === null) {
        return ownRoles;
    }

    return getAdminPanelRolesFromClosestConfiguration(parent, nodes, userCustomerRelations);
};
