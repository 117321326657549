import { useContext, useMemo } from 'react';

import { AppAbilityType } from '../authorization';
import AbilityContext from '../context/ability-context';

const useAuthorization = () => {
    const context: AppAbilityType = useContext(AbilityContext);

    if (context === undefined) {
        throw new Error('useAuthContext is only usable within AuthProvider');
    }

    return useMemo(() => context, [context]);
};

export default useAuthorization;
