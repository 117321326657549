import React from 'react';
import { BgColorExplosion, BgColorExplosionProps } from '@zetadisplay/engage-components';
import BackgroundImage from '@zetadisplay/engage-components/assets/background-image';

import Loader from '../loader/loader';

const bgColorExplosionProps: BgColorExplosionProps = {
    sx: {
        background: `url("${BackgroundImage.src}") no-repeat center center`,
    },
};

const LoaderWithBackground = () => {
    return (
        <>
            <BgColorExplosion {...bgColorExplosionProps} />

            <Loader />
        </>
    );
};

export default LoaderWithBackground;
