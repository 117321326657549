import React, { useMemo } from 'react';
import { OpenAPIConfig } from '@zetadisplay/connect-adminpanel-api-client';
import { useSession } from 'next-auth/react';
import { useEnvContext } from 'next-runtime-env';

import createUacApi from '../utils/create-uac-api';
import AutomatedUserCreationContext from './automated-user-creation-context';
import ProcessEnv = NodeJS.ProcessEnv;

type Props = React.PropsWithChildren<{
    accessToken?: string;
    apiConfig: OpenAPIConfig;
}>;

const AutomatedUserCreationProvider = ({ accessToken, apiConfig, children }: Props) => {
    const { NEXT_PUBLIC_UAC_API_BASE } = useEnvContext() as ProcessEnv;

    const { data } = useSession();

    const values = useMemo(
        () => ({
            api: createUacApi({
                ...apiConfig,
                BASE: NEXT_PUBLIC_UAC_API_BASE,
                TOKEN: accessToken || data?.accessToken,
            }),
        }),

        [NEXT_PUBLIC_UAC_API_BASE, accessToken, apiConfig, data?.accessToken]
    );

    return <AutomatedUserCreationContext.Provider value={values}>{children}</AutomatedUserCreationContext.Provider>;
};

export default AutomatedUserCreationProvider;
