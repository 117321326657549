import { useAsyncAbortable } from 'react-async-hook';
import { UserApplicationStatisticsDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useNotify } from '@zetadisplay/zeta-ui-components/hooks';

import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import onErrorHandler from '../../dependency-injection/utils/on-error-handler';

const useApplicationStatistics = () => {
    const api = useAdminPanelApi();
    const notify = useNotify();

    return useAsyncAbortable<UserApplicationStatisticsDto>(
        async () => {
            return api.users.getUserApplicationStatistics();
        },
        [api.users],
        {
            onError: (error) => onErrorHandler(error, notify),
        }
    );
};

export default useApplicationStatistics;
