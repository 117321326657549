import { RoleDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

const getUserPermissions = (user: UserDto): RoleDto['permissions'] =>
    user.customers
        .map((customer) => customer.roles)
        .flat()
        .filter((role): role is RoleDto => !!role)
        .map((role) => role.permissions)
        .flat()
        .map((permission) => permission);

export default getUserPermissions;
