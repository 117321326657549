import { ApiError } from '@zetadisplay/connect-adminpanel-api-client';

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

/* TODO Do this shit properly - one error handler for them all? */
type EngageError = {
    message: string;
    response: {
        data: {
            detail: string;
        };
    };
};

type Errors = ApiError | Error | EngageError;

const isApiError = (error: Errors): error is ApiError => (error as ApiError).body !== undefined;
const isEngageError = (error: Errors): error is EngageError =>
    (error as EngageError).response?.data?.detail !== undefined;

const handleAdminPanelResponseError = (error: Errors): string => {
    return (
        (isApiError(error) && error?.body?.message) ||
        (isEngageError(error) && error?.response?.data?.detail) ||
        error.message ||
        DEFAULT_ERROR_MESSAGE
    );
};

export default handleAdminPanelResponseError;
