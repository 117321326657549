'use client';

import React, { useMemo } from 'react';
import { OpenAPIConfig } from '@zetadisplay/connect-adminpanel-api-client';
import { useEnvContext } from 'next-runtime-env';

import createApi from '../utils/create-api';
import DependencyInjectionContext from './dependency-injection-context';
import ProcessEnv = NodeJS.ProcessEnv;

type Props = React.PropsWithChildren<{
    accessToken?: string;
    apiConfig: OpenAPIConfig;
}>;

const DependencyInjectionProvider = ({ accessToken, apiConfig, children }: Props) => {
    const { NEXT_PUBLIC_ADMIN_PANEL_API_URL } = useEnvContext() as ProcessEnv;

    const values = useMemo(() => {
        return {
            api: createApi({ ...apiConfig, BASE: NEXT_PUBLIC_ADMIN_PANEL_API_URL, TOKEN: accessToken }),
        };
    }, [NEXT_PUBLIC_ADMIN_PANEL_API_URL, accessToken, apiConfig]);

    return <DependencyInjectionContext.Provider value={values}>{children}</DependencyInjectionContext.Provider>;
};

export default DependencyInjectionProvider;
