'use client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ContentWrapper from '../src/layout/components/content-wrapper';

const Page = () => {
    return (
        <ContentWrapper title="Not found">
            <Box>
                <Typography>Content you are trying to access does not exist.</Typography>
            </Box>
        </ContentWrapper>
    );
};

export default Page;
