'use client';

import React, { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import { DefaultLayout } from '@zetadisplay/engage-components/layouts';
import { DefaultLayoutContainerProps } from '@zetadisplay/engage-components/layouts/components/default-layout-container';
import { ThemeProps } from '@zetadisplay/zeta-ui-components';
import { useLocalStorage } from '@zetadisplay/zeta-ui-components/hooks';
import { useRouter } from 'next/navigation';
import { signIn, useSession } from 'next-auth/react';
import { SnackbarProvider } from 'notistack';

import LoaderWithBackground from '../src/components/loader-with-background/loader-with-background';
import config from '../src/config/config';
import Header from '../src/layout/components/header';
import Navigation from '../src/layout/components/navigation/navigation';
import AuthorizationProvider from '../src/modules/authorization/context/authorization-provider';
import DependencyInjectionProvider from '../src/modules/dependency-injection/components/dependency-injection-provider';
import ProductFruitsWrapper from '../src/utils/product-fruits/product-fruits-wrapper';

const ProtectedLayout = ({ children }: { children: React.ReactNode }) => {
    const [darkMode, setDarkMode] = useLocalStorage<boolean>('adminpanel.options.dark-mode', false);
    const router = useRouter();

    const { data, status } = useSession<true>({
        required: true,
        onUnauthenticated() {
            signIn('okta');
        },
    });

    const onResetError = useCallback(() => {
        router.push('/');
    }, [router]);

    const layoutContainerProps = useMemo(
        (): DefaultLayoutContainerProps => ({
            errorBoundaryProps: {
                onResetError,
            },
            fullWidthOnSmall: false,
            fullWidth: true,
        }),
        [onResetError]
    );

    const themeProps = useMemo((): ThemeProps => ({ dark: darkMode, version: 1 }), [darkMode]);

    if (status === 'loading') {
        return <LoaderWithBackground />;
    }

    if (status === 'authenticated' && data) {
        return (
            <DefaultLayout LayoutContainerProps={layoutContainerProps} ThemeProps={themeProps}>
                <Box
                    data-testid="protected-layout"
                    sx={{
                        backgroundColor: 'background.contentSecondary',
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                    }}
                >
                    <DependencyInjectionProvider accessToken={data.accessToken} apiConfig={config.adminPanelApiConfig}>
                        <AuthorizationProvider>
                            <SnackbarProvider maxSnack={5}>
                                <ProductFruitsWrapper>
                                    <Header darkMode={darkMode} setDarkMode={setDarkMode} />

                                    <Box sx={{ display: 'flex', height: '100%', flexGrow: 1 }}>
                                        <Navigation />

                                        {children}
                                    </Box>
                                </ProductFruitsWrapper>
                            </SnackbarProvider>
                        </AuthorizationProvider>
                    </DependencyInjectionProvider>
                </Box>
            </DefaultLayout>
        );
    }

    return <div>Unauthenticated</div>;
};

export default ProtectedLayout;
