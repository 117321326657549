import { RoleDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import Action from '../../authorization/action';

/**
 * This function maps User's permissions to allowed actions on 'Application-subject'.
 *
 * @param user
 * @param permissions List of Permissions the User has for that specific Customer
 * @param limitedFeatures In case of external technical issues, permissions can be limited to read-only
 * @returns
 */
const getApplicationsAbilities = (
    user: UserDto,
    permissions: RoleDto['permissions'],
    limitedFeatures: boolean
): Action | Action[] | undefined => {
    if (user.type === UserDto.type.ADMINISTRATOR || permissions.includes('APPLICATIONS_FULL_ACCESS')) {
        return (limitedFeatures && [Action.Read]) || [Action.Manage, Action.Read];
    }

    if (permissions.includes('APPLICATIONS_VIEW_ACCESS')) {
        return Action.Read;
    }

    return undefined;
};

export default getApplicationsAbilities;
