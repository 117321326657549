import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { BarChart, HighlightScope, mangoFusionPaletteDark, mangoFusionPaletteLight } from '@mui/x-charts';
import { Theme } from '@zetadisplay/zeta-ui-components';
import { ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

import { APPLICATIONS } from '../../applications/application';
import useApplicationStatistics from '../../users/hooks/use-application-statistics';

const highlightScope: HighlightScope = {
    highlighted: 'item',
    faded: 'global',
};

const UserApplicationStatistics = () => {
    const theme: ZetaTheme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const { result } = useApplicationStatistics();

    const adminPanel = result?.adminPanel || 0;
    const engage = result?.engage || 0;
    const contentCreator = result?.contentCreator || 0;

    const width = matches ? 400 : 350;
    const height = matches ? 300 : 250;

    return (
        <Box component="section" data-testid="user-application-statistics">
            <Theme dark={theme.palette.dark} nested version={2}>
                <BarChart
                    colors={(theme.palette.dark && mangoFusionPaletteDark) || mangoFusionPaletteLight}
                    xAxis={[{ scaleType: 'band', data: [`Users by applications`] }]}
                    series={[
                        { data: [adminPanel], label: APPLICATIONS[0].name, highlightScope },
                        { data: [engage], label: APPLICATIONS[3].name, highlightScope },
                        { data: [contentCreator], label: APPLICATIONS[1].name, highlightScope },
                    ]}
                    width={width}
                    height={height}
                    slotProps={{
                        legend: {
                            labelStyle: {
                                fontSize: matches ? 14 : 12,
                            },
                        },
                    }}
                />
            </Theme>
        </Box>
    );
};

export default UserApplicationStatistics;
