import { useAsyncAbortable } from 'react-async-hook';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useNotify } from '@zetadisplay/zeta-ui-components/hooks';

import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import onErrorHandler from '../../dependency-injection/utils/on-error-handler';

const useCurrentUser = (defaultUser?: UserDto) => {
    const api = useAdminPanelApi();

    const notify = useNotify();

    return useAsyncAbortable(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async (abortSignal) => {
            if (defaultUser) {
                return defaultUser;
            }

            return api.userProfile.getProfile().then((data) => {
                return data;
            });
        },
        [api.userProfile, defaultUser],
        {
            onError: (error) => onErrorHandler(error, notify),
        }
    );
};

export default useCurrentUser;
