import Typography from '@mui/material/Typography';

type Props = {
    title: string;
};
const SectionTitle = ({ title }: Props) => (
    <Typography variant="h2" sx={{ padding: '6px 16px' }}>
        {title}
    </Typography>
);

export default SectionTitle;
