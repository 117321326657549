import { ApplicationDto, RoleDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import Action from '../../../authorization/action';
import userHasApplication from '../../../users/utils/user-has-application';

const getEngagePlayersAbilities = (
    user: UserDto,
    permissions: RoleDto['permissions'],
    limitedFeatures: boolean
): Action | Action[] | undefined => {
    const hasEngageApplicationAccess = userHasApplication(user, ApplicationDto.externalSystem.ENGAGE);

    if (
        user.type === UserDto.type.ADMINISTRATOR ||
        (hasEngageApplicationAccess && permissions.includes('PLAYERBATCH_ENGAGE_FULL_ACCESS'))
    ) {
        return (limitedFeatures && [Action.Read]) || [Action.Manage, Action.Read];
    }

    if (hasEngageApplicationAccess && permissions.includes('PLAYERBATCH_ENGAGE_VIEW_ACCESS')) {
        return Action.Read;
    }

    return undefined;
};

export default getEngagePlayersAbilities;
