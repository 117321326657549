import { __spreadArray } from "tslib";
import { useCallback, useEffect } from 'react';
export function useProductFruitsApi(callback, deps) {
    var memoizedCallback = useCallback(callback, deps);
    useEffect(function () {
        if (typeof window === 'undefined')
            return;
        var disposer;
        var readyListener = function () {
            var _a;
            disposer = memoizedCallback((_a = window.productFruits) === null || _a === void 0 ? void 0 : _a.api);
        };
        if (window.productFruitsIsReady && window.productFruits && window.productFruits.api) {
            disposer = memoizedCallback(window.productFruits.api);
            window.removeEventListener('productfruits_ready', readyListener);
        }
        else {
            window.addEventListener('productfruits_ready', readyListener, { once: true });
        }
        return function () {
            window.removeEventListener('productfruits_ready', readyListener);
            typeof disposer == 'function' && disposer();
        };
    }, __spreadArray(__spreadArray([], deps, true), [memoizedCallback], false));
}
export function isProductFruitsReady() {
    if (typeof window === 'undefined')
        return false;
    return window.productFruitsIsReady === true;
}
