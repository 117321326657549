'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import NextError from 'next/error';

import ContentWrapper from '../src/layout/components/content-wrapper';

const GlobalError = ({ error }: { error: Error & { digest?: string } }) => {
    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    return (
        <ContentWrapper title="Error">
            {/* This is the default Next.js error component, but it doesn't allow omitting the statusCode property yet. */}
            <NextError statusCode={undefined as never} />
        </ContentWrapper>
    );
};

export default GlobalError;
