import React from 'react';
import { Loader as ZUICLoader, LoaderProps } from '@zetadisplay/zeta-ui-components';

import imageRenderer from '../../utils/image-renderer/image-renderer';

type Props = Omit<LoaderProps, 'imageRenderer'>;

const Loader = ({ fixed }: Props) => {
    return <ZUICLoader fixed={fixed} imageRenderer={imageRenderer} />;
};

export default Loader;
