import { useState } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { SearchState } from '@zetadisplay/zeta-ui-components/components/search';
import { useNotify } from '@zetadisplay/zeta-ui-components/hooks';

import useResults from '../../../hooks/use-results';
import getResultData from '../../../utils/get-result-data';
import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import onErrorHandler from '../../dependency-injection/utils/on-error-handler';
import { CustomerSearchState, CustomersSearchFilters } from '../customer';
import { useCustomerCreatedEventListener } from '../events/customer-created-event';
import { useCustomerRemovedEventListener } from '../events/customer-removed-event';
import { useCustomerSearchEventListener } from '../events/customer-search-event';
import { useCustomerUpdatedEventListener } from '../events/customer-updated-event';

export type UseCustomersReturnType = {
    data: CustomerDto[] | undefined;
    error: Error | undefined;
    isLoading: boolean;
    refresh: () => Promise<CustomerDto[] | undefined>;
};

export const initialState: CustomerSearchState = {
    id: [],
    externalSystem: [],
    parentCustomerId: [],
    search: '',
    status: undefined,
};

const useCustomers = (
    customerSearchState?: CustomerSearchState | undefined,
    disabled = false,
    listenSearchEvent = true
): UseCustomersReturnType => {
    const api = useAdminPanelApi();
    const notify = useNotify();

    const [queryParams, setQueryParams] = useState<CustomerSearchState>(customerSearchState || initialState);

    const { results, removeResult, setResult, setResults } = useResults<CustomerDto>(JSON.stringify(queryParams));

    const customers = useAsyncAbortable<CustomerDto[] | undefined>(
        async () => {
            if (disabled) {
                return undefined;
            }

            return api.customers
                .getCustomers(
                    queryParams.id,
                    queryParams.externalSystem,
                    queryParams.parentCustomerId?.map((item) => item.value),
                    queryParams.search,
                    queryParams.status
                )
                .then((data) => setResults(data));
        },
        [
            api.customers,
            disabled,
            queryParams.externalSystem,
            queryParams.id,
            queryParams.parentCustomerId,
            queryParams.search,
            queryParams.status,
            setResults,
        ],
        {
            onError: (error) => onErrorHandler(error, notify),
            setLoading: (state) => ({ ...state, loading: true }),
        }
    );

    useCustomerCreatedEventListener((data: CustomerDto) => {
        setResult(data);
    });

    useCustomerUpdatedEventListener((data: CustomerDto) => {
        setResult(data);
    });

    useCustomerRemovedEventListener((data: CustomerDto) => {
        removeResult(data);
    });

    useCustomerSearchEventListener((data: SearchState<CustomersSearchFilters>) => {
        if (listenSearchEvent) {
            setQueryParams(data as CustomerSearchState);
        }
    });

    return {
        data: getResultData(results),
        error: customers.error,
        isLoading: customers.loading,
        refresh: customers.execute,
    };
};

export default useCustomers;
