import { OpenAPIConfig } from '@zetadisplay/connect-contentplatform-api-client';

const copConfig: OpenAPIConfig = {
    BASE: '',
    VERSION: 'v1',
    WITH_CREDENTIALS: false,
    CREDENTIALS: 'omit',
};

export default copConfig;
