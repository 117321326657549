import React from 'react';
import Box from '@mui/material/Box';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useDocumentTitle } from '@zetadisplay/zeta-ui-components/hooks';

import { APP_NAME } from '../../config/contants';
import useAuthorization from '../authorization/hooks/use-auth';
import useCurrentUser from '../authorization/hooks/use-current-user';
import CustomerStatistics from './components/customer-statistics';
import UserStatistics from './components/user-statistics';

const Dashboard = () => {
    useDocumentTitle(APP_NAME, ['Dashboard']);

    const currentUser = useCurrentUser();

    const context = useAuthorization();

    const canReadCustomers = currentUser.type !== UserDto.type.USER && context.can('read', 'Customer');

    return (
        <Box component="section" data-testid="dashboard">
            <UserStatistics />

            {canReadCustomers && <CustomerStatistics />}
        </Box>
    );
};

export default Dashboard;
