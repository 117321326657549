import { useEffect, useRef } from 'react';
import { productFruits } from 'product-fruits';
export function ProductFruits(props) {
    if (props.dontDestroy != null) {
        console.error('<ProductFruits /> - dontDestroy is deprecated and it WILL NOT work, use lifeCycle instead');
        return null;
    }
    useEffect(function () {
        productFruits.init(props.workspaceCode, props.language, props.user, props.config);
        props.debug && console.log('react-product-fruits - initialized');
        return function () {
            var _a, _b;
            if (!props.lifeCycle || props.lifeCycle == 'neverUnmount') {
                props.debug && console.log('react-product-fruits - skipping destroying, lifeCycle default or set to neverUnmount', props.lifeCycle);
            }
            else if (props.lifeCycle == 'unmount') {
                props.debug && console.log('react-product-fruits - destroying');
                /** @ts-ignore */ // TEMP
                (_b = (_a = window === null || window === void 0 ? void 0 : window.productFruits) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.destroy();
            }
        };
    }, []);
    var isInitialMount = useRef(true);
    useEffect(function () {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        }
        else {
            props.debug && console.log('react-product-fruits - user prop updated');
            productFruits.safeExec(function ($api) {
                $api.push(['updateUserData', props.user]);
            });
        }
    }, [props.user]);
    return null;
}
export * from './hooks';
